import React, { useEffect, useState } from 'react';
import { LineContext } from 'components/context';
import UsersCreatePage from 'pages/users/create';
import { getUserInfo } from 'services/user.service';
import usePages from 'src/customHooks/pages';

export default function Home(props) {
  const { message, token } = usePages();
  const [user, setUser] = useState();
  const [bindingPhoneNumber, setBindingPhoneNumber] = useState(false);
  useEffect(() => {
    if (token) {
      getUserInfo().then((user) => {
        setBindingPhoneNumber(user.Phone);
        if (user.patients.length !== 0) {
          const self = user.patients.find((u) => u.isSelf);
          setUser(self);
        }
      });
    }
  }, [token]);

  if (token) {
    return (
      <LineContext.Provider value={message}>
        <UsersCreatePage
          {...props}
          {...message}
          user={user}
          bindingPhoneNumber={bindingPhoneNumber}
        ></UsersCreatePage>
      </LineContext.Provider>
    );
  }
  return null;
}
